import React, { useEffect, useRef, useState } from 'react'
import type { LottiePlayer } from 'lottie-web'

import styles from 'styles/Loading.module.scss'

const Loading = () => {
  const ref = useRef<HTMLDivElement>(null)
  const [lottie, setLottie] = useState<LottiePlayer | null>(null)

  useEffect(() => {
    import('lottie-web').then((Lottie) => setLottie(Lottie.default))
  }, [])

  useEffect(() => {
    if (lottie && ref.current) {
      const animation = lottie.loadAnimation({
        container: ref.current,
        renderer: 'svg',
        loop: true,
        autoplay: true,
        path: '/animation/loading.json',
      })

      return () => animation.destroy()
    }
  }, [lottie])

  return (
    <div className={styles.loadingSection}>
      <div ref={ref} className={styles.loading} />
    </div>
  )
}

export default Loading
