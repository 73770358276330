import React, { ReactElement, useState, useEffect } from 'react'
import Link from 'next/link'
import { useRouter } from 'next/router'
import clsx from 'clsx'

import IconMenu from '../public/svg/ic-menu.svg'
import IconAccount from '../public/svg/ic-account.svg'
import IconShoppingCard from '../public/svg/ic-shopping-cart.svg'
// import IconSearch from '../public/svg/ic-search.svg'
import IconClose from '../public/svg/ic-close.svg'
import BabystepLogo from '../public/svg/baby-logo.svg'
import styles from 'styles/Navbar.module.scss'

function Navbar(): ReactElement {
  const router = useRouter()

  const [active, setActive] = useState(false)

  const menuList = [
    { label: 'หน้าแรก', link: '/', subPath: '' },
    { label: 'แกลลอรี่', link: '/gallery', subPath: '/gallery/images' },
    // { label: 'สินค้าทั้งหมด', link: '/product', subPath: '' },
    // { label: 'รีวิวสินค้า', link: '/review', subPath: '' },
    // { label: 'ถาม-ตอบ', link: '/faq', subPath: '' },
  ]

  useEffect(() => {
    if (active) {
      document.body.classList.add('no-scroll')
    } else {
      document.body.classList.remove('no-scroll')
    }
  }, [active])

  return (
    <div className={styles.navbar}>
      <div className="container">
        <div className={styles.actions} style={{ visibility: 'hidden' }}>
          <IconAccount className={styles.actionsIcon} />
          <span className={styles.divider}></span>
          <IconShoppingCard className={styles.actionsIcon} />
        </div>
        <nav className={styles.nav}>
          <div className={styles.logo}>
            <BabystepLogo />
          </div>

          <div className={styles.menu}>
            <ul className={styles.menuList}>
              {menuList.map((menu) => (
                <Link key={menu.label} href={menu.link} passHref>
                  <li
                    className={clsx([
                      styles.menuItem,
                      (router.pathname === menu.link ||
                        (router.pathname.includes(menu.link) &&
                          router.pathname === menu.subPath)) &&
                        styles.menuItemActive,
                    ])}
                  >
                    {menu.label}
                  </li>
                </Link>
              ))}
            </ul>
            <div>
              <IconMenu
                className={clsx([
                  styles.menuIcon,
                  active && styles.menuIconInActive,
                ])}
                onClick={() => setActive((prev) => !prev)}
              />

              <IconClose
                className={clsx([
                  styles.menuIcon,
                  !active && styles.menuIconInActive,
                ])}
                onClick={() => setActive((prev) => !prev)}
              />
              {/* <IconSearch className={styles.searchIcon} /> */}
            </div>
          </div>
        </nav>
      </div>
      <div
        className={clsx([
          styles.menuDisplay,
          'animate__animated',
          active && 'animate__slideInDown',
          active && styles.menuDisplayActive,
        ])}
      >
        <ul className={styles.menuMobileList}>
          {menuList.map((menu) => (
            <Link key={menu.label} href={menu.link} passHref>
              <li
                className={clsx([
                  styles.menuMobileItem,
                  router.asPath === menu.link && styles.menuMobileItemActive,
                ])}
              >
                {menu.label}
              </li>
            </Link>
          ))}
        </ul>
      </div>
    </div>
  )
}

export default Navbar
