import React, { ReactElement } from 'react'
import Link from 'next/link'
import clsx from 'clsx'

// import { HomeRegistration } from 'features/Home'

import IconInstagram from '../public/svg/ic-instagram.svg'
import IconFacebook from '../public/svg/ic-facebook.svg'
import IconInstagramOutline from '../public/svg/ic-instagram-outline.svg'
import IconFacebookOutline from '../public/svg/ic-facebook-outline.svg'
import IconCalling from '../public/svg/ic-calling.svg'
import IconMessage from '../public/svg/ic-message.svg'
import IconDown from '../public/svg/ic-down.svg'
import BabystepLogo from '../public/svg/baby-logo.svg'

import { configs } from 'config'
import styles from 'styles/Footer.module.scss'

type InfoProps = {
  title: string
  link: string
  key: string
  isMobileShow: boolean
  children: { title: string; icon?: JSX.Element; link: string }[]
}

type SocialProps = {
  link: string
  key: string
  title: string
  icon?: JSX.Element
  iconMobile?: JSX.Element
}

function Footer(): ReactElement {
  const socials: SocialProps[] = [
    {
      key: 'facebook',
      title: 'babystep facebook',
      link: configs.facebookUrl,
      icon: <IconFacebookOutline />,
      iconMobile: <IconFacebook />,
    },
    {
      key: 'instargram',
      title: 'babystep instagram',
      link: configs.instagramUrl,
      icon: <IconInstagramOutline />,
      iconMobile: <IconInstagram />,
    },
  ]

  const infos: InfoProps[] = [
    // {
    //   title: 'สินค้าทั้งหมด',
    //   link: '',
    //   key: 'all',
    //   isMobileShow: false,
    //   children: [
    //     {
    //       title: 'เซ็ตรองเท้า',
    //       link: '',
    //     },
    //     {
    //       title: 'เซ็ตเสื้อ',
    //       link: '',
    //     },
    //     {
    //       title: 'ผ้าห่อตัว',
    //       link: '',
    //     },
    //   ],
    // },
    // {
    //   title: 'ข้อมูลอื่นๆ',
    //   link: '',
    //   key: 'another',
    //   isMobileShow: false,
    //   children: [
    //     {
    //       title: 'แกลลอรี่',
    //       link: '',
    //     },
    //     {
    //       title: 'รีวิวสินค้า',
    //       link: '',
    //     },
    //     {
    //       title: 'ถาม-ตอบ',
    //       link: '',
    //     },
    //   ],
    // },
    {
      title: 'ติดต่อ',
      link: '',
      key: 'contact',
      isMobileShow: true,
      children: [
        // {
        //   title: configs.phoneNumber,
        //   icon: <IconCalling className={styles.infoLinksContactIcon} />,
        //   link: configs.phoneNumberLink
        //     ? `tel:${configs.phoneNumberLink}`
        //     : '#',
        // },
        {
          title: configs.email,
          icon: <IconMessage className={styles.infoLinksContactIcon} />,
          link: configs.email ? `mailto:${configs.email}` : '#',
        },
        {
          title: configs.facebookPage,
          icon: <IconFacebook className={styles.infoLinksContactIcon} />,
          link: configs.facebookUrl,
        },
      ],
    },
  ]
  return (
    <div className={styles.footerWrapper}>
      <div className={styles.footerBg}>
        <div className={styles.footer}>
          <div className={styles.title}>
            <div className={styles.titleLogo}>
              <BabystepLogo />
            </div>

            <div className={styles.socials}>
              {socials.map((social) => {
                return (
                  <Link href={social.link} key={social.key} passHref>
                    <a
                      href={social.link}
                      target="_blank"
                      rel="noreferrer"
                      className={styles.socialsIcon}
                      title={social.title}
                    >
                      {social.iconMobile}
                    </a>
                  </Link>
                )
              })}
            </div>
          </div>
          <div className={styles.infos}>
            <ul
              className={styles.infoLinks}
              style={{ gridTemplateColumns: '1fr' }}
            >
              {infos.map((info) => {
                return (
                  <li key={info.title}>
                    <a
                      href={`#${info.key}`}
                      className={clsx([
                        styles.infoLinksTitle,
                        info.isMobileShow && styles.infoLinksTitleNone,
                      ])}
                    >
                      <span>{info.title}</span>
                      {!info.isMobileShow && (
                        <span className={styles.infoLinksTitleIcon}>
                          <IconDown />
                        </span>
                      )}
                    </a>

                    <ul
                      className={clsx([
                        styles.infoLinksChidren,
                        info.isMobileShow && styles.infoLinksShowSection,
                      ])}
                      style={{
                        display: 'flex',
                        justifyContent: 'space-between',
                        flexWrap: 'wrap',
                      }}
                      id={info.key}
                    >
                      <div>
                        <Link href="tel:+66808829894" passHref>
                          <li className={styles.infoLinksContact}>
                            <IconCalling
                              className={styles.infoLinksContactIcon}
                            />
                            080-882-9894
                          </li>
                        </Link>
                        <Link href="tel:+66896249355" passHref>
                          <li className={styles.infoLinksContact}>
                            <IconCalling
                              className={styles.infoLinksContactIcon}
                            />
                            089-624-9355
                          </li>
                        </Link>
                      </div>

                      {info.children.map((cinfo) => {
                        return (
                          <Link href={cinfo.link} key={cinfo.title} passHref>
                            <a href="/test">
                              <li className={styles.infoLinksContact}>
                                {cinfo.icon && cinfo.icon}
                                {cinfo.title}
                              </li>
                            </a>
                          </Link>
                        )
                      })}
                    </ul>
                  </li>
                )
              })}
              {/* <li>
                <HomeRegistration onSubmit={(data) => console.log(data)} />
              </li> */}
            </ul>
          </div>
        </div>
      </div>

      <div className={styles.company}>
        <span>Babystepshoesbkk © 2021 All rights reserved</span>
        <div className={styles.companySocials}>
          {socials.map((social) => {
            return (
              <Link href={social.link} key={social.key} passHref>
                <a
                  href={social.link}
                  target="_blank"
                  rel="noreferrer"
                  className={styles.companySocialsIcon}
                  title={social.title}
                >
                  {social.icon}
                </a>
              </Link>
            )
          })}
        </div>
      </div>
    </div>
  )
}

export default Footer
