import React, { ReactPortal, PropsWithChildren, useEffect } from 'react'
import ReactDOM from 'react-dom'
import clsx from 'clsx'

import IconClose from '../public/svg/ic-close.svg'
import styles from 'styles/Modal.module.scss'

type ModalProps = {
  isShowing: boolean
  hide: () => void
  className?: string
}
function Modal(props: PropsWithChildren<ModalProps>): ReactPortal | null {
  const { isShowing, hide, children, className } = props

  useEffect(() => {
    if (isShowing) {
      document.body.classList.add('no-scroll')
    } else {
      document.body.classList.remove('no-scroll')
    }
  }, [isShowing])

  return isShowing
    ? ReactDOM.createPortal(
        <React.Fragment>
          <div className={styles.modalOverlay} />
          <div
            className={styles.modalWrapper}
            aria-modal
            aria-hidden
            tabIndex={-1}
            role="dialog"
          >
            <div className={styles.modalBackground}>
              <div className={clsx([styles.modal, className])}>
                <span
                  className={styles.modalClose}
                  onClick={hide}
                  onKeyDown={hide}
                  tabIndex={-1}
                  role="button"
                >
                  <IconClose />
                </span>
                <div className={styles.modalBody}>{children}</div>
              </div>
            </div>
          </div>
        </React.Fragment>,
        document.body
      )
    : null
}

export default Modal
