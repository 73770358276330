import React, { ReactElement } from 'react'
import type { PropsWithChildren } from 'react'

import styles from 'styles/LandingLayout.module.scss'

type Props = PropsWithChildren<Record<string, unknown>>

function LandingBgGalleryAllLayout(props: Props): ReactElement {
  const { children } = props

  return <div className={styles.landingGalleryAllWrapper}>{children}</div>
}

export default LandingBgGalleryAllLayout
