import React, { ReactElement } from 'react'
import type { PropsWithChildren } from 'react'

import { Navbar, Footer } from 'components'

import styles from 'styles/LandingLayout.module.scss'

type Props = PropsWithChildren<Record<string, unknown>>

function LandingLayout(props: Props): ReactElement {
  const { children } = props

  return (
    <>
      <div className={styles.navbarWrapper}>
        <Navbar />
      </div>
      <main className={styles.main}>{children}</main>
      <div className={styles.footerWrapper}>
        <Footer />
      </div>
    </>
  )
}

export default LandingLayout
