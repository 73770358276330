export const configs = {
  apiPath: process.env.API_PATH || 'https://baby-step-back.herokuapp.com',
  phoneNumber: process.env.NEXT_PUBLIC_PHONE_NUMBER || '082-XXX-XXXX',
  phoneNumberLink: process.env.NEXT_PUBLIC_PHONE_NUMBER_LINK || '#',
  email: process.env.NEXT_PUBLIC_EMAIL || 'babystepbkk@gmail.com',
  instagramUrl:
    process.env.NEXT_PUBLIC_INSTAGRAM_URL ||
    'https://www.instagram.com/babystepshoes',
  facebookUrl:
    process.env.NEXT_PUBLIC_FACEBOOK_URL ||
    'https://www.facebook.com/babystepshoesbkk',
  facebookPage: process.env.NEXT_PUBLIC_FACEBOOK_PAGE || '@babystepshoesbkk',
}
