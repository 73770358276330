import { ReactElement, PropsWithChildren } from 'react'

import React, { useState, useRef } from 'react'
import clsx from 'clsx'
import { isEmpty, noop } from 'lodash'

import useOnClickOutside from 'utils/hooks/useOnClickOutside'
import IconDown from '../public/svg/ic-down.svg'

import styles from 'styles/Select.module.scss'

export type Option = {
  label: string
  // eslint-disable-next-line @typescript-eslint/no-explicit-any
  key: any
}

interface Props {
  className?: string
  // eslint-disable-next-line @typescript-eslint/no-explicit-any
  onChange?: (value: any) => void
  options: Option[]
  placeholder?: string
  selected?: Option
  label?: string
  disabled?: boolean
  variants?: 'primary' | 'secondary'
  fullWidth?: boolean
}

function Select(props: PropsWithChildren<Props>): ReactElement {
  const {
    placeholder = '',
    selected,
    label,
    disabled = false,
    className,
    onChange,
    variants,
    options,
    fullWidth = false,
  } = props
  const [show, setShow] = useState(false)
  const selectedOption = typeof selected === 'object' && !isEmpty(selected)

  const _handleClose = () => setShow(false)

  const _handleToggle = () => {
    setShow((prevShow) => !prevShow)
  }
  const divRef = useRef(null)
  useOnClickOutside(divRef, _handleClose)

  const _handleSelected = (_selected: Option) => {
    if (!onChange) {
      return null
    }

    onChange(_selected)
    _handleClose()
  }

  return (
    <div
      className={clsx(
        styles.select,
        {
          [styles.selectDisabled]: disabled,
          [styles.selectFullWidth]: fullWidth,
        },
        className
      )}
    >
      <div
        className={styles.selectContainer}
        ref={divRef}
        tabIndex={0}
        role="menu"
      >
        <div
          role="menuitem"
          tabIndex={0}
          className={clsx([
            styles.selectControl,
            show && styles.selectControlShow,
            !!variants && styles.selectControlSecondary,
          ])}
          onClick={disabled ? noop : _handleToggle}
          onKeyDown={disabled ? noop : _handleToggle}
        >
          <div
            className={clsx(
              styles.selectSingleValue,
              !selectedOption && styles.selectPlaceholder,
              !!variants && styles.selectPlaceholderSecondary
            )}
          >
            {selectedOption && label ? (
              <React.Fragment>
                <span className={styles.selectLabel}>{label}</span>
                {selected?.label}
              </React.Fragment>
            ) : (
              selectedOption && selected?.label
            )}
            {!selectedOption && label ? (
              <React.Fragment>
                <span className={styles.selectLabel}>{label}</span>
                placeholder
              </React.Fragment>
            ) : (
              !selectedOption && placeholder
            )}
          </div>
          <div
            className={clsx([
              styles.selectIndicator,
              show && styles.selectIndicatorShow,
              !!variants && styles.selectIndicatorSecondary,
            ])}
          >
            <IconDown className={clsx(show && styles.selectRotate)} />
          </div>
        </div>
        <div className={clsx(styles.selectMenu, show && styles.selectMenuShow)}>
          <div className={styles.selectMenuList}>
            {options.map((option) => {
              return (
                <div
                  key={option.key}
                  className={clsx(
                    styles.selectOption,
                    selected?.key === option.key && styles.selectOptionSelect
                  )}
                  tabIndex={0}
                  onClick={() => _handleSelected(option)}
                  onKeyDown={() => _handleSelected(option)}
                  role="menuitem"
                >
                  {option.label}
                </div>
              )
            })}
          </div>
        </div>
      </div>
    </div>
  )
}

export default Select
