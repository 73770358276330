import React, {
  InputHTMLAttributes,
  ReactElement,
  forwardRef,
  LegacyRef,
} from 'react'
import clsx from 'clsx'

import styles from 'styles/Input.module.scss'

interface Props extends InputHTMLAttributes<HTMLInputElement> {
  variants?: 'primary' | 'secondary'
  fullWidth?: boolean
}

export type Ref = LegacyRef<HTMLInputElement> | undefined

function Input(props: Props, ref: Ref): ReactElement {
  const { className, variants = 'primary', fullWidth = false, ...rest } = props
  const cn = clsx([
    styles.input,
    className,
    variants === 'secondary' && styles.inputSecondary,
    fullWidth && styles.inputFullWidth,
  ])

  return <input ref={ref} className={cn} {...rest} />
}

export default forwardRef(Input)
