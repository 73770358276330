import React, { PropsWithChildren, ButtonHTMLAttributes } from 'react'
import clsx from 'clsx'

import styles from 'styles/Button.module.scss'

interface Props extends ButtonHTMLAttributes<HTMLButtonElement> {
  variants?: 'primary' | 'secondary'
}

const Button = React.forwardRef<HTMLButtonElement, PropsWithChildren<Props>>(
  (props, ref) => {
    const {
      children,
      className,
      type = 'button',
      variants = 'primary',
      ...rest
    } = props
    const cn = clsx([
      styles.button,
      className,
      variants === 'secondary' && styles.buttonSecondary,
    ])

    return (
      <button ref={ref} type={type} className={cn} {...rest}>
        {children}
      </button>
    )
  }
)

Button.displayName = 'Button'

export default Button
