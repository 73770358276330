import React, { ReactElement } from 'react'
import type { PropsWithChildren } from 'react'
import Image from 'next/image'

import styles from 'styles/LandingLayout.module.scss'

type Props = PropsWithChildren<Record<string, unknown>>

function LandingBgGalleryLayout(props: Props): ReactElement {
  const { children } = props

  return (
    <div className={styles.landingGalleryWrapper}>
      <span className={styles.landingGalleryBackground}>
        <span
          style={{
            position: 'relative',
            width: '100%',
            height: '827px',
            maxWidth: '1440px',
          }}
        >
          <Image
            src="/images/gallery-bg-1.png"
            alt="background gallery"
            layout="fill"
          />
        </span>
        <span
          style={{
            position: 'relative',
            width: '100%',
            height: '827px',
            maxWidth: '1440px',
          }}
        >
          <Image
            src="/images/gallery-bg-1.png"
            alt="background gallery"
            layout="fill"
          />
        </span>
        <span
          style={{
            position: 'relative',
            width: '100%',
            height: '827px',
            maxWidth: '1440px',
          }}
        >
          <Image
            src="/images/gallery-bg-1.png"
            alt="background gallery"
            layout="fill"
          />
        </span>
      </span>
      {children}
    </div>
  )
}

export default LandingBgGalleryLayout
